<template>
    <el-container>
        <!-- width自适应,不然header与aside有间隔 -->
        <el-aside width="auto">
            <common-aside/>
        </el-aside>
        <el-container>
            <el-header>
                <common-header/>
            </el-header>
            <common-tags/>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'
import CommonTags from '../components/CommonTags.vue'
import { myMenu } from '@/api/menu'
export default {
    data(){
        return{}
    },
    components:{
        CommonAside,
        CommonHeader,
        CommonTags
    },
    created() {
        console.log(1111)
        myMenu(null, (data) => {
                            data.unshift({
                                label: "首页",
                                name: "home",
                                path: "/home",
                                url: "Home.vue"
                            })
            console.log(data)
            // 设置菜单
            this.$store.commit('setMenu',data)
            // 动态添加路由
            this.$store.commit('addMenu',this.$router)
            // 跳转到首页
            //this.$router.push('/home')
        })
    }
}
</script>

<style lang="less" scoped>
.el-header{
    padding:0;
}
</style>
