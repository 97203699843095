import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index'
import store from './store/index'
import Cookie from 'js-cookie'
import './api/mock'

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    if (Cookie.get('token')) {
      store.commit('addMenu', router)
    }
  }
}).$mount('#app')

Vue.prototype.numberToTimeStr = function (timestamp, withoutTime){
  if (!timestamp) {
    return null
  }
  let date = new Date(timestamp * 1000)
  let year = date.getFullYear(); // 获取年份
  let month = date.getMonth() + 1; // 获取月份，需要+1因为月份是从0开始计算的
  month = month < 10 ? "0" + month : month
  let day = date.getDate(); // 获取日
  day = day < 10 ? "0" + day : day
  if (withoutTime) {
    return year + "-" + month + "-" + day
  }
  let hours = date.getHours(); // 获取小时
  hours = hours < 10 ? "0" + hours : hours
  let minutes = date.getMinutes(); // 获取分钟
  minutes = minutes < 10 ? "0" + minutes : minutes
  let seconds = date.getSeconds();
  seconds = seconds < 10 ? "0" + seconds : seconds
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
}

Vue.prototype.dateStrToNumber = function(dateString) {
  var parts = dateString.split(' ');
  var dateParts = parts[0].split('-');
  var timeParts = parts[1].split(':');
  
  var year = parseInt(dateParts[0], 10);
  var month = parseInt(dateParts[1], 10) - 1; // 月份是从0开始的
  var day = parseInt(dateParts[2], 10);
  var hour = parseInt(timeParts[0], 10);
  var minute = parseInt(timeParts[1], 10);
  var second = parseInt(timeParts[2], 10);
  
  return new Date(year, month, day, hour, minute, second).getTime() / 1000;
}

Vue.prototype.formatName = function(name, lang) {
  return JSON.parse(name)[lang]
}